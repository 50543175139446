import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Info = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <circle
        cx={12}
        cy={12}
        r={7.5}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <rect
        fill={color}
        height={7}
        rx={1}
        strokeWidth={strokeWidth}
        width={2}
        x={11}
        y={10}
      />
      <circle
        cx={12}
        cy={8}
        fill={color}
        r={1}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Info.defaultProps = {
  color: 'currentColor',
};

Info.displayName = 'Info';

export default Info;
