import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Clipboard = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, strokeWidth, ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M15 3.5H9c-.276 0-.5.336-.5.75v1.5c0 .414.224.75.5.75h6c.276 0 .5-.336.5-.75v-1.5c0-.414-.224-.75-.5-.75Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.77 5h-.924c-.49 0-.96.184-1.305.513C6.194 5.84 6 6.286 6 6.75v10.5c0 .464.194.91.54 1.237.347.329.817.513 1.306.513h8.308c.49 0 .96-.184 1.305-.513.346-.328.541-.773.541-1.237V6.75c0-.464-.195-.91-.54-1.237A1.899 1.899 0 0 0 16.153 5h-.923"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
});

Clipboard.defaultProps = {
  color: 'currentColor',
};

Clipboard.displayName = 'Clipboard';

export default Clipboard;
